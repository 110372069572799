import React from 'react';

// import computer from '@/assets/image/computer.svg';
import cel from '@/assets/image/cel.png';
import contentCell from '@/assets/image/kpop.gif';

import './style/banner.scss';

export const Banner = () => {

    return (
        <div className="component-banner">
            <div className="image">
                <div className="box"></div>
                <svg version="1.0" viewBox="0 0 1175 851" preserveAspectRatio="xMidYMid meet">
                <defs>
                    <linearGradient  id="header-shape-gradient" x2="0.45" y2="0.85">
                        <stop offset="0%" stopColor="#004eff" />
                        <stop offset="100%" stopColor="#a200ff" />
                    </linearGradient>
                </defs>
                    <g id="group" transform="translate(300,700) scale(0.1,-0.1)" stroke="none">
                        <path d="M0 8348 c1 -668 179 -1310 495 -1783 243 -364 537 -621 930 -815 358 -177 716 -270 1190 -310 166 -14 632 -13 735 2 135 19 589 16 740 -5 682 -95 1134 -374 1370 -845 196 -394 222 -950 75 -1602 -19 -85 -35 -164 -35 -176 0 -29 98 -225 157 -311 202 -299 488 -506 828 -598 387 -106 883 -61 1395 125 357 130 466 159 682 180 200 19 414 -11 585 -83 267 -113 473 -352 591 -689 36 -102 62 -155 138 -280 240 -395 514 -708 799 -912 104 -75 296 -169 408 -201 202 -57 453 -48 628 22 l39 15 0 4214 0 4214 -5875 0 -5875 0 0 -162z"/>
                    </g>
                </svg> 
            </div>
            <div className="computer">
                {/* <img alt="" src={computer} /> */}
                <img alt="" src={cel} />
                <div className="content-cell">
                    <img src={contentCell} alt="" />
                </div>
            </div>
            <div className="content">
                <div>
                    Seu catálogo on-line
                </div>
                <div>
                   <span className='color-5'>catálogo</span>
                </div>
                <div className='resume'>
                    Imagine encontrar tudo o que precisa sem sair de casa, com apenas alguns cliques. Com a nossa solução, isso é possível! Oferecemos uma ampla variedade de recursos de alta qualidade, que podem atender às suas necessidades.
                </div>
                <div className="buttons">
                    <div className="button">
                        Conheça nossa solução
                    </div>
                </div>
            </div>
            <div className="button-scroll">
                <div className="button"></div>
            </div>
        </div>
    )
}