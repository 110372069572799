import React from "react";


import initial from '@/assets/image/spring-swing-rocket.gif';
import develop from '@/assets/image/fireworks-.gif';
import turbo from '@/assets/image/rocket.gif';
import especial from '@/assets/image/mall.gif';

import '../style/prices.scss';

export const Prices = () => {

    // const [period, setPeriod] = useState(false);

    return (
        <div className="component-prices">
            <div className="section-title">
                Nossos planos
            </div>
            <div className="area-products">
                <div className="product">
                    <div className="icon-card">
                        <img src={initial} alt="" />
                    </div>
                    <div className="title">
                        Inícial
                    </div>
                    <div className="text">
                        Você pode criar uma vitrine online personalizada para exibir seus produtos e serviços. Você terá acesso a recursos básicos, como a capacidade de adicionar fotos e descrições de produtos e preços.
                    </div>
                </div>
                <div className="product">
                    <div className="icon-card">
                        <img src={develop} alt="" />
                    </div>
                    <div className="title">
                        Lançamento
                    </div>
                    <div className="text">
                    Oferece ainda mais recursos para aprimorar a sua vitrine online. Você pode personalizar ainda mais a sua vitrine, incluindo recursos avançados de exibição, como a capacidade de adicionar vídeos e animações para destacar seus produtos e serviços.
                    </div>
                </div>
                <div className="product">
                    <div className="icon-card">
                        <img src={turbo} alt="" />
                    </div>
                    <div className="title">
                        Turbo
                    </div>
                    <div className="text">
                        Você pode exibir seus produtos em uma vitrine personalizada com suporte para recursos premium, como a capacidade de adicionar interatividade em tempo real para seus clientes, como bate-papo ao vivo e suporte telefônico.
                    </div>
                </div>
            </div>
            <div className="especial-cases">
                <div className="icon">
                    <img src={especial} alt="" />
                </div>
                <div className="text">
                    <p>Se você é proprietário de uma empresa com filiais, sabe que gerenciar várias unidades pode ser um desafio. Cada filial tem suas próprias necessidades, e é importante ter um plano especial para cada uma delas. Entre em contato.</p>
                </div>
            </div>
            <div className="see-all">
                Caso queira saiber mais sobre todos os planos e ferramentas <span className="click-here">clique aqui</span>.
            </div>
        </div>
    )
}