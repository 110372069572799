import React from "react";


import instagram from '@/assets/image/instagram.png';
import youtube from '@/assets/image/youtube.png';
import twitter from '@/assets/image/twitter.png';
import facebook from '@/assets/image/facebook.png';
import telegram from '@/assets/image/telegram.png';
import whatsapp from '@/assets/image/whatsapp.png';

import './style/footer.scss';

export const Footer = () =>{
    return (
        <div className="component-footer">
            <div>
                <div className="name">Ktal@go</div>
                <div>
                   <small>seu catálogo on-line</small>
                </div>
            </div>
            <div>
                links
            </div>
            <div className="social-media">
                <div>
                    <img src={instagram} alt="" />
                </div>
                <div>
                    <img src={facebook} alt="" />
                </div>
                <div>
                    <img src={youtube} alt="" />
                </div>
                <div>
                    <img src={twitter} alt="" />
                </div>
                <div>
                    <img src={telegram} alt="" />
                </div>
                <div>
                    <img src={whatsapp} alt="" />
                </div>
            </div>
            <div>
                Todos direitos reservados
            </div>
        </div>
    )
}