import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PageHome } from './pages/home';

import './assets/style/main.scss';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<PageHome />} />
        <Route path='*' element={<>Home 404</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
