import React from "react";

import './style/contact.scss';

export const Contact = () => {
    return (
        <div className="component-contact">
            <div className="area-form">
                <form action="">
                    <div>
                        <input placeholder="Nome" type="text" />
                    </div>
                    <div>
                        <input placeholder="Email" type="email" />
                    </div>
                    <div>
                        <select placeholder="Já é cliente?">
                            <option value={0}>Já é cliente?</option>
                            <option>Sim</option>
                            <option>Não</option>
                        </select>
                    </div>
                    <div>
                        <select placeholder="Assunto">
                            <option value={0}>Assunto</option>
                            <option>Dúvida</option>
                            <option>Sugestão</option>
                            <option>Reclamação</option>
                        </select>
                    </div>
                    <div className="description">
                        <textarea placeholder="Escreava aqui sua mensagem" />
                    </div>
                    <div className="send-message">
                        <button>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}