import React from 'react';
import { Banner } from '../../components/banner/banner';
import { MenuHome } from '@/components/menu/home';
import { Prices } from './components/prices';
import { Gallery } from './components/gallery';
import { Contact } from '@/components/contact/contact';
import { Footer } from '../../components/footer/footer';

import time from '../../assets/image/24-hours.gif';
import responsive from '../../assets/image/responsive.gif';
import custom from '../../assets/image/properties.gif';
import kpop from '../../assets/image/cashier.gif';

import iahuman from '../../assets/image/Sandy_Tech-20_Single-04.jpg';
//import iahuman from '../../assets/image/character_23.jpg';
import iahumanselect from '../../assets/image/4650132.jpg';

import './style/home.scss';

export const PageHome = () => {
    return (
        <div className="page-home">
            <section className="menu">
                <MenuHome /> 
            </section>
            <header className="banner">
                <Banner />
            </header>
            <section id='ktalago' name="ktalago" className="content">
                <div className="section-title">
                    Sobre nós
                </div>
                <div className="text-about-us" style={{"--max-image": "450px"}}>
                    <div className="text">
                        <p>
                            Somos uma empresa totalmente digital que tem o objetivo de fornecer uma plataforma fácil e conveniente para nossos clientes exibirem seus produtos e serviços. Acreditamos que o poder da tecnologia pode ajudar as empresas a alcançar seus objetivos, por isso criamos uma plataforma inovadora que é controlada por inteligência artificial monitorada por uma equipe humana altamente capacitada.
                        </p>
                    </div>
                    <div className="image">
                        <img src={iahuman} alt="" />
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="available">
                    <div>
                        <div className="icon">
                            <img src={time} alt="" />
                        </div>
                        <div className="title">
                            Sempre disponível
                        </div>
                        <div className='text'>
                            24 Horas por dia, 7 dias por semana
                        </div>
                    </div>
                    <div>
                        <div className="icon">
                            <img src={responsive} alt="" />
                        </div>
                        <div className="title">
                            Acesse de qualquer lugar
                        </div>
                        <div className='text'>
                            De qualquer dispositivo e lugar
                        </div>
                    </div>
                    <div>
                        <div className="icon">
                            <img src={custom} alt="" />
                        </div>
                        <div className="title">
                            Você no controle
                        </div>
                        <div className='text'>
                            Personalize e deixe com sua cara
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className="text-about-us" style={{"--max-image": "550px"}}>
                    <div className="image">
                        <img className='image-filter' src={iahumanselect} alt="" />
                    </div>
                    <div className="text">
                        <p>Nosso sistema utiliza tecnologia avançada para garantir que todas as decisões tomadas sejam precisas e confiáveis. A inteligência artificial é capaz de processar grandes quantidades de dados para identificar padrões e tendências, permitindo que nossos clientes tomem decisões informadas sobre seus negócios. Além disso, nossa equipe humana está sempre monitorando o sistema para garantir que tudo esteja funcionando perfeitamente.</p>
                        <p>Acreditamos que a tecnologia é uma ferramenta poderosa para ajudar as empresas a crescer e se destacar no mercado. É por isso que estamos empenhados em fornecer uma plataforma online confiável e fácil de usar que ajudará nossos clientes a exibir seus produtos e serviços com facilidade e eficiência.</p>
                    </div>
                </div>
                <div className="text-about-us" style={{"--max-image": "250px"}}>
                    <div className="text">
                        <p>Ao escolher nossa vitrine online, você terá a vantagem de trabalhar com uma equipe de profissionais experientes que estão comprometidos em fornecer a melhor experiência possível para nossos clientes. Estamos sempre procurando maneiras de melhorar nossa plataforma e garantir que nossos clientes estejam satisfeitos com o serviço que fornecemos.</p>
                        <p>Então, se você está procurando uma vitrine online fácil de usar, confiável e inovadora, você veio ao lugar certo. Nós somos uma empresa totalmente digital que utiliza tecnologia avançada para ajudar nossos clientes a alcançarem seus objetivos de negócios. Escolha nossa plataforma online hoje e descubra como podemos ajudar sua empresa a crescer e se destacar no mercado.</p>
                    </div>
                    <div className="image">
                        <img src={kpop} alt="" />
                    </div>
                </div>
            </section>
            <section className='content images'>
                <div className="section-images">
                    <Gallery />
                </div>
            </section>
            <section id='plan' name="plan" className='content'>
                <Prices />
            </section>
            <section className='content video'>
                <div className="section-video">
                    <div className="text">
                        <div>
                            Ainda tem dúvidas?
                        </div>
                        <div>
                            Assista o vídeo para saber mais.
                        </div>
                    </div>
                    <div className="video">
                        
                    </div>
                </div>
            </section>
            <div id='contact' name="contact" className="content">
                <div className="section-title">
                    Contato
                </div>
                <Contact />
            </div>
            <section className="content newsletter">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#FF0066" d="M15.8,-32.8C19.3,-25.4,20.1,-18.7,28.7,-13.4C37.3,-8.1,53.5,-4,59,3.2C64.5,10.4,59.3,20.8,53.2,30.3C47,39.9,40,48.5,31,58.1C22,67.6,11,78,-0.1,78.2C-11.3,78.5,-22.6,68.6,-35.3,61.2C-48,53.8,-62.2,49,-69.7,39.2C-77.2,29.4,-78.1,14.7,-78.6,-0.3C-79.2,-15.3,-79.4,-30.7,-71.3,-39.4C-63.2,-48.2,-46.9,-50.5,-33.6,-52.3C-20.3,-54.1,-10.2,-55.4,-2,-52C6.2,-48.5,12.3,-40.2,15.8,-32.8Z" transform="translate(100 100)" />
                </svg>
                <div className="section-content">
                    <div className="section-title">
                        Junte-se à nossa comunidade
                    </div>
                    <p>
                        Receba novidades sobre o nosso produto
                    </p>
                    <div className="form-newsletter">
                        <div>
                            <input name='name' type="text" placeholder='Insira seu nome' />
                        </div>
                        <div>
                            <input name='name' type="mail" placeholder='Insira seu e-mail' />
                        </div>
                        <div>
                            <button>Inscrever-se</button>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}