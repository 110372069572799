import React, { useCallback, useEffect, useState } from "react";

import '../style/gallery.scss';

export const Gallery = () => {

    const [list, setList] = useState();

    const getData = useCallback(async () => {
        const listdata = [
            {image:'Image 01', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 02', row: 2, collumn: 1, w: 150, h: 250},
            {image:'Image 03', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 04', row: 2, collumn: 1, w: 150, h: 250},
            {image:'Image 05', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 06', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 07', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 08', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 09', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 10', row: 2, collumn: 1, w: 150, h: 250},
            {image:'Image 11', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 12', row: 2, collumn: 1, w: 150, h: 250},
            {image:'Image 13', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 14', row: 1, collumn: 1, w: 150, h: 150},
            {image:'Image 15', row: 1, collumn: 2, w: 250, h: 150}
        ];

        setList(listdata);
    }, [])


    const createCard = useCallback((_data, _id) => {
        const _url = `url(https://via.placeholder.com/${_data.w}x${_data.h}.png)`;
        return <div key={`photo-${_id}`} className="card" style={{'--row': _data.row, '--collumn': _data.collumn, '--image': _url}}>
            <div className="image">
                
            </div>
        </div>
    }, []);

    useEffect(()=> {
        getData();
    }, [getData])

    return (
        <div className="component-gallery">
            {
                list?.map((item, id) => createCard(item, id) )
            }
        </div>
    )
}