import React, {useEffect, useRef, useCallback} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import { SITE_MAP } from '../../provider/site/map';

import logo from '@/assets/image/logo.png';

import './style/home.scss';

export const MenuHome = () => {

    const navigate = useNavigate();

    const menu = useRef();
    
    const scroller = (e) => {
        if(e.target.body.querySelector('#root').getBoundingClientRect().y === 0){
            menu.current?.classList?.remove('fixed');
        }else{
            menu.current?.classList?.add('fixed');
        }
    }

    const controllerScroll = useCallback(() => {
        window.removeEventListener('scroll', scroller);
        window.addEventListener('scroll', scroller)
    }, [])

    useEffect(()=>{
        controllerScroll();
    }, [controllerScroll])

    return (
        <div ref={menu} className="component-menu-home">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="menu">
                <input id='bt-menu' type="checkbox" />
                <label htmlFor="bt-menu">
                    <div></div>
                    <div></div>
                    <div></div>
                </label>
                <ul>
                    {SITE_MAP?.map((item, k) => {
                        return <li key={`menu-${k}`} >
                            <Link to={item.url}>{item.label}</Link>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}